
export default function Footer() {
    return (
        <div className="w-full border-t border-gray-200 py-3 text-center text-sm text-gray-500">
            <span className="m-2">&copy; {new Date().getFullYear()}</span>
            <span className="m-2"><a href={process.env.PUBLIC_URL+'/povprivacypolicy.pdf'} title="POV Privacy Policy">Privacy Policy</a></span>
            <span className="m-2"><a href={process.env.PUBLIC_URL+'/communityguidelines.pdf'} title="POV Community Guidelines">Community Guidelines</a></span>
            <span className="m-2"><a href="mailto:povsocialcontact@gmail.com" rel="nofollow" title="Contact POV social">Contact</a></span>
        </div>
    );
}